import React from 'react';
import * as sty from '../style';

export default function NotFound() {
    return (
        <sty.background>
            <sty.hint>Oops, we couldn't find this page!</sty.hint>
        </sty.background>
    );
}
